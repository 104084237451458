import React, { FC } from 'react'
import styled from 'styled-components'

const GreenHeaderStyled = styled.h2`
&:before{
  content: '';
}
`

export interface IRulesGreenHeader {
  greenHeader: string
}

const GreenHeader: FC<IRulesGreenHeader> = ({greenHeader}) => (
  <div className="bg-white">
    <div className="container">
      <GreenHeaderStyled className='
      text-2xl sm:text-2.5xl tracking-wide text-center leading-none text-almostBlackBlue font-roag font-black italic uppercase
      mt-10 mb-8 md:my-12 pb-1
      relative z-10
      before:bg-appLightGreen before:transform before:skew-x-170 before:rounded-tl-lg
      before:rounded-br-lg before:w-full before:h-4 before:sm:h-4.5 before:bottom-0 before:absolute before:block before:-z-10
 '>{greenHeader}</GreenHeaderStyled>
    </div>
  </div>
)

export default GreenHeader